import { ProfileHistoryOrderAccordion, ProfileHistoryOrderSummary } from './profileHistoryOrder.module.css';
import { OrderType, Product } from '../../../../types/types';
import { REPLACE_CART } from '../../../../Utils/Constants/constants';
import { Context } from '../../../../Utils/Store/store';
import { pluralize } from '../../../../Utils/utils';
import { getRussiaStatusName, isOrderIsClosed, monthNames } from '../../../../Utils/orderHistoryUtils';
import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionActions from '@material-ui/core/AccordionActions';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import CircularProgress from '@material-ui/core/CircularProgress';
import { navigate } from 'gatsby';
import moment from 'moment';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  icon: {
    verticalAlign: 'bottom',
    height: 20,
    width: 20,
  },
  details: {
    alignItems: 'center',
  },
  column: {
    flexBasis: '33.33%',
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2),
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  summary: {
    flexDirection: 'column',
  },
}));

interface ProfileHistoryOrderProps {
    items: [];
    date: string;
    status: string;
    isExpanded: boolean;
    createdTime: string;
    orderId: string;
    sum: number;
    orderType: OrderType;
    discount: number;
}

const ProfileHistoryOrder = ({
  items, date, status, isExpanded, orderId, sum, discount, createdTime, orderType
}: ProfileHistoryOrderProps) => {
  const { state, dispatch } = useContext(Context);
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);

  const addProductsBackToCart = () => {
    const newCart = [];

    items.forEach((itm) => {
      const findVal = state.products
        .find((pr: Product) => {
          setIsLoading(true);

          return pr.iikoID === itm.code
        || (pr.Options && pr.Options.some((opt) => opt.iikoID === itm.code));
        });

      if (findVal) {
        if (!findVal.iikoID) {
          const prodOption = findVal.Options.find((v) => v.iikoID === itm.code);

          newCart.push({
            ...findVal,
            productQuantity: itm.amount,
            OptionSelectedID: itm.code,
            Price: prodOption.Price,
            Name: `${findVal.Name} | ${prodOption.Name}`,
          });
        } else {
          newCart.push({ ...findVal, productQuantity: itm.amount });
        }
      }
    });

    if (newCart.length > 0) {
      dispatch({ type: REPLACE_CART, payload: newCart });
    }

    setIsLoading(false);

    navigate('/cart');
  };

  useEffect(() => {
    if (isLoading === true) {
      addProductsBackToCart();
    }
  }, [isLoading]);

  const handleAddToCartClicked = () => {
    setIsLoading(true);
  };

  const getLastDate = () => {
    if (!date && !createdTime) {
      return null;
    }

    if (!date && createdTime) {
      const jsdate = createdTime ? new Date(createdTime) : null;

      return `Заказ от ${jsdate.getDate()} ${monthNames[jsdate.getMonth()]} ${jsdate.toTimeString().substr(0, 5)}`;
    }

    const jsdate = date ? new Date(date) : null;

    const now = moment();
    const end = moment(date);

    const duration = moment.duration(end.diff(now));

    if (duration.asMinutes() < 1 || isOrderIsClosed(status) || status === 'Готово') {
      return `Заказ от ${jsdate.getDate()} ${monthNames[jsdate.getMonth()]} ${jsdate.toTimeString().substr(0, 5)}`;
    }

    const days = Math.round(duration.asDays());

    if (days < 1) {
      return `Будет доставлено до ${jsdate.toTimeString().substr(0, 5)}`;
    }
    return `Будет доставлено до ${jsdate.getDate()} ${monthNames[jsdate.getMonth()]} ${jsdate.toTimeString().substr(0, 5)}`;
  };

  return (
    <Accordion
      className={ProfileHistoryOrderAccordion}
      defaultExpanded={isExpanded}
    >
      <AccordionSummary
        id="panel1c-header"
      >
        <div className={ProfileHistoryOrderSummary}>
          <Typography className={classes.heading}>
            {getLastDate()}
          </Typography>
          <Typography className={classes.heading}>
            {getRussiaStatusName(status)}
          </Typography>
        </div>
      </AccordionSummary>
      <AccordionDetails className={classes.details}>
        <List>

          {items.length > 0 && items.map((val) => (
            <ListItem>
              {/* <ListItemAvatar>
                <Avatar>
                  <NigiriIcon />
                </Avatar>
              </ListItemAvatar> */}
              <ListItemText
                primary={val.name}
                secondary={`${val.amount} порц.`}
              />
            </ListItem>
          ))}
          <Divider />
          <ListItem>
            <ListItemText
              primaryTypographyProps={{ color: 'textSecondary' }}
              primary={`Сумма к оплате: ${sum} ₽`}
            />
          </ListItem>
        </List>
      </AccordionDetails>
      <AccordionActions>
        {isLoading ? <CircularProgress color="primary" /> : (
          <Button size="small" onClick={handleAddToCartClicked} color="primary">
            Добавить в корзину
          </Button>
        )}
      </AccordionActions>
    </Accordion>
  );
};

export default ProfileHistoryOrder;

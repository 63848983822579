import ProfileFieldsDelivery from './ProfileFieldsDelivery/profileFieldsDelivery';
import { ProfileFields as ProfileFieldsClass } from './profileFields.module.css';
import ProfileFieldsContacts from './ProfileFieldsContacts/profileFieldsContacts';
import { useFormik } from 'formik';
import * as yup from 'yup';
import React, { useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

interface ProfileFieldsProps {
  infoFromApi: {},
  isLoading: boolean;
}

const validationSchema = yup.object({
  phone: yup
    .string()
    .matches(/^(\+?7|8)?9\d{9}$/, 'Что-то не так с номером!')
    .required('Нужно обязательно указать номер телефона!'),
  name: yup
    .string()
    .max(60, 'Имя должно быть не больше 60 символов')
    .required('Нужно указать как к вам обращаться'),
  email: yup
    .string()
    .max(60, 'Имейл должен быть не больше 60 символов')
    .email(),
});

const ProfileFields = ({ infoFromApi, isLoading }: ProfileFieldsProps) => {
  const [editingContacts, setEditingContacts] = useState(false);

  const formik = useFormik({
    initialValues: {
      phone: infoFromApi.Phone || '',
      name: infoFromApi.Name || '',
      email: infoFromApi.Email || '',
    },
    enableReinitialize: true,
    validationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    validateOnMount: false,
    onSubmit: (values) => {
    },
  });

  return (
    isLoading ? <div style={{ width: '100%', justifyContent: 'center', display: 'flex' }}><CircularProgress color="primary" /></div> : (
      <div className={ProfileFieldsClass}>
        <ProfileFieldsContacts
          editingContacts={editingContacts}
          setEditingContacts={setEditingContacts}
          set
          formik={formik}
        />
        {/* <ProfileFieldsDelivery
          formik={formik}
        /> */}
      </div>
    )
  );
};

export default ProfileFields;

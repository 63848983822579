import Profile from '../routing/Profile/profile';
import PrivateRoute from '../routing/PrivateRoute/privateRoute';
import React from 'react';
import { Router } from '@reach/router';

const ProfilePage = () => (
  <Router>
    <PrivateRoute path="/profile" component={Profile} />
  </Router>
);

export default ProfilePage;

import { AuthContext } from '../../Utils/Store/store';
import { isBrowser } from '../../Utils/utils';
import { navigate } from 'gatsby';
import Cookies from 'js-cookie';
import React, { useContext } from 'react';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const userIsAuthd = Cookies.getJSON('user_is_authd');

  if (
    !userIsAuthd
        && isBrowser
  ) {
    // If we’re not logged in, redirect to the home page.
    navigate('/');
    return null;
  }
  return (
    <Component {...rest} />
  );
};

export default PrivateRoute;

export const monthNames = ['Января', 'Февраля', 'Марта', 'Апреля', 'Мая', 'Июня', 'Июля', 'Августа', 'Сентября', 'Октября', 'Ноября', 'Декабря'];

// Source: https://docs.google.com/document/d/1pRQNIn46GH1LVqzBUY5TdIIUuSCOl-A_xeCBbogd2bE/edit#bookmark=kix.rzibi1mtrn6p
export const getRussiaStatusName = (name: string) => {
  switch (name.toUpperCase()) {
    case 'NEW':
      return 'Новый';
    case 'WAITING':
      return 'В ожидании';
    case 'ON_WAY':
      return 'В пути';
    case 'CLOSED':
      return 'Закрыт';
    case 'CANCELLED':
      return 'Отменён';
    case 'DELIVERED':
      return 'Доставлен';
    case 'UNCONFIRMED':
      return 'Не подтвержден';
    case 'READY':
      return 'Готов';
    case 'IN PROGRESS':
      return 'Готовится';
    default:
      return name;
  }
};

export const isOrderIsClosed = (status: string) => {
  const upperStatus = status.toUpperCase();

  if (upperStatus === 'CANCELLED' || upperStatus === 'CLOSED' || upperStatus === 'DELIVERED' || upperStatus === 'ОТМЕНЕНА' || upperStatus === 'ДОСТАВЛЕНА' || upperStatus === 'ЗАКРЫТА') {
    return true;
  }
  return false;
};

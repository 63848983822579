import { ProfileTabs as ProfileTabsClass } from './profileTabs.module.css';
import ButtonText from '../../../components/Blocks/ButtonText/buttonText';
import { LOGOUT } from '../../../Utils/Constants/constants';
import { AuthContext } from '../../../Utils/Store/store';
import React, { useContext } from 'react';
import { navigate } from '@reach/router';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Button from '@material-ui/core/Button';
import useMediaQuery from '@material-ui/core/useMediaQuery';

interface ProfileTabsProps {
    activeTab: number;
    setActiveTab: (arg: number) => void;
}

const a11yProps = (index) => ({
  id: `simple-tab-${index}`,
  'aria-controls': `simple-tabpanel-${index}`,
});

const ButtonInTabs = ({ className, onClick, children }) => (
  <Button className={className} children={children} color="secondary" onClick={onClick} />
);

const ProfileTabs = ({ activeTab, setActiveTab }: ProfileTabsProps) => {
  const { authState, authDispatch } = useContext(AuthContext);

  const matches = useMediaQuery('(max-width:600px)');

  const handleExitButtonClick = () => {
    authDispatch({ type: LOGOUT });
    navigate('/');
  };

  const handleChange = (e, value) => {
    switch (value) {
      default:
      case 0:
        setActiveTab(0);
        return navigate('/profile/?tab=orders');
      case 1:
        setActiveTab(1);
        return navigate('/profile/?tab=settings');
    }
  };

  return (
    <div className={ProfileTabsClass}>
      <Tabs variant={matches ? 'fullWidth' : 'scrollable'} indicatorColor="primary" textColor="primary" value={activeTab} onChange={handleChange} aria-label="Навигация по профилю">
        <Tab label="Заказы" {...a11yProps(0)} />
        <Tab label="Настройки" {...a11yProps(1)} />
        <ButtonInTabs onClick={handleExitButtonClick}>
          {matches ? 'Выйти' : 'Выйти из аккаунта'}
        </ButtonInTabs>
      </Tabs>
    </div>
  );
};

export default ProfileTabs;

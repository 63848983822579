import CookingIcon from './icons/cooking.svg';
import DeliveryIcon from './icons/delivery.svg';
import SuccessIcon from './icons/success.svg';
import AddedIcon from './icons/added.svg';
import { OrderType } from '../../../../types/types';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';

interface ProfileHistoryStepperProps {
    status: string;
    orderType: OrderType;
}

const activeIconStyle = {
  backgroundColor: '#90D61E',
  borderRadius: '100%',
  boxShadow: '0px 4px 13px rgba(153, 218, 16, 0.61)',
  width: '50px',
  height: '50px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const passiveIconStyle = {
  border: '1px solid #eeeeee',
  borderRadius: '100%',
  width: '50px',
  height: '50px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const steps = [{ label: 'Заказ принят', icon: <CookingIcon /> }, { label: 'Готовится', icon: <CookingIcon /> }, { label: 'Заказ у курьера', icon: <DeliveryIcon /> }, { label: 'Готово', icon: <SuccessIcon /> }];
const stepsSelfService = [{ label: 'Заказ принят', icon: <CookingIcon /> }, { label: 'Готовится', icon: <CookingIcon /> }, { label: 'Можно забирать', icon: <SuccessIcon /> }];

function ColorlibStepIcon(props) {
  const { active, completed } = props;

  const icons = {
    1: <AddedIcon />,
    2: <CookingIcon />,
    3: <DeliveryIcon />,
    4: <SuccessIcon />,
  };

  return (
    <div
      style={active ? activeIconStyle : passiveIconStyle}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

function ColorlibStepIconSelfService(props) {
  const { active, completed } = props;

  const icons = {
    1: <AddedIcon />,
    2: <CookingIcon />,
    3: <SuccessIcon />,
  };

  return (
    <div
      style={active ? activeIconStyle : passiveIconStyle}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

const getActiveStep = (status: string) => {
  switch (status.toUpperCase()) {
    case 'NEW':
    case 'НОВАЯ':
      return 0;
    case 'IN PROGRESS':
    case 'ГОТОВИТСЯ':
    case 'ГОТОВО':
    case 'READY':
      return 1;
    case 'AWAITING DELIVERY':
    case 'ЖДЕТ ОТПРАВКИ':
    case 'В ПУТИ':
    case 'WAITING':
    case 'ON THE WAY':
    case 'ON_WAY':
      return 2;
    case 'ЗАКРЫТА':
    case 'CLOSED':
    case 'ДОСТАВЛЕНА':
    case 'DELIVERED':
      return 3;
    default:
      return 'NEW';
  }
};
const getActiveStepSelfService = (status: string) => {
  switch (status.toUpperCase()) {
    case 'NEW':
    case 'НОВАЯ':
      return 0;
    case 'IN PROGRESS':
    case 'ГОТОВИТСЯ':
      return 1;
    case 'ГОТОВО':
    case 'READY':
    case 'ЗАКРЫТА':
    case 'CLOSED':
    case 'ДОСТАВЛЕНА':
    case 'DELIVERED':
      return 3;
    default:
      return 0;
  }
};

const ProfileHistoryStepper = ({ status, orderType }: ProfileHistoryStepperProps) => (
  <Stepper alternativeLabel nonLinear activeStep={orderType && orderType.orderServiceType === 'DELIVERY_PICKUP' ? getActiveStepSelfService(status) : getActiveStep(status)}>
    {orderType && orderType.orderServiceType === 'DELIVERY_PICKUP' ? stepsSelfService.map((val) => (
      <Step key={val.label}>
        <StepLabel StepIconComponent={ColorlibStepIconSelfService}>
          {val.label}
        </StepLabel>
      </Step>
    )) : steps.map((val) => (
      <Step key={val.label}>
        <StepLabel StepIconComponent={ColorlibStepIcon}>
          {val.label}
        </StepLabel>
      </Step>
    ))}
  </Stepper>
);

export default ProfileHistoryStepper;

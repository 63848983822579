import strapiAPI from './strapiApi';

const changeContactInfo = (phone, email, name) => strapiAPI.post('/users-permissions/createorupdate',
  {
    phone,
    email,
    name,
  });

export default changeContactInfo;

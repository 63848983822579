import { ProfileHistory as ProfileHistoryClass, ProfileHistoryPara, ProfileHistorySelfServiceMap, ProfileHistorySelfServiceMapContainer } from './profileHistory.module.css';
import ProfileHistoryOrder from './ProfileHistoryOrder/profileHistoryOrder';
import ProfileHistoryStepper from './ProfileHistoryStepper/profileHistoryStepper';
import H2 from '../../../components/Blocks/H2/H2';
import Paragraph from '../../../components/Blocks/Paragraph/paragraph';
import { isOrderIsClosed } from '../../../Utils/orderHistoryUtils';
import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';

interface ProfileHistoryProps {
  userOrders: {};
  isLoading?: boolean;
}

const showSelfServiceMap = (status: string, orderType: OrderType) => {
  if (!orderType) {
    return false;
  }

  if (status.toUpperCase() === 'ГОТОВА' && orderType.orderServiceType === 'DELIVERY_PICKUP') {
    return true;
  }

  return false;
};

const Orders = React.memo(({ userOrders }: ProfileHistoryProps) => Object.keys(userOrders).length !== 0
  && userOrders
    .map((val) => (
      (!isOrderIsClosed(val.status))
        ? (
          <>
            <ProfileHistoryStepper status={val.status} orderType={val.orderType} />
            {showSelfServiceMap(val.status, val.orderType) && <div className={ProfileHistorySelfServiceMapContainer}><iframe title="Карта самовывоза" src="https://yandex.ru/map-widget/v1/-/CCUuiMS0wA" width="100%" height="400" frameBorder="1" allowFullScreen className={ProfileHistorySelfServiceMap} /></div>}
            <ProfileHistoryOrder
              isExpanded
              status={val.status}
              discount={val.discount}
              orderType={val.orderType}
              items={val.items}
              createdTime={val.createdTime}
              date={val.deliveryDate ? val.deliveryDate : val.date}
              sum={val.sum}
              orderId={val.orderId}
            />
            <Box m={5}>
              <Divider light variant="middle" />
            </Box>
          </>
        )
        : (
          <>
            <ProfileHistoryOrder
              isExpanded={false}
              status={val.status}
              orderId={val.orderId}
              orderType={val.orderType}
              discount={val.discount}
              sum={val.sum}
              createdTime={val.createdTime}
              items={val.items}
              date={val.date}
            />
          </>
        )
    )));

const ProfileHistory = ({ userOrders, isLoading }: ProfileHistoryProps) => (
  isLoading ? <div style={{ width: '100%', justifyContent: 'center', display: 'flex' }}><CircularProgress color="primary" /></div> : (
    <div className={ProfileHistoryClass}>
      <H2>История заказов</H2>
      {userOrders.length === 0
       && (
       <Paragraph className={ProfileHistoryPara}>
         У вас пока не было заказов!
       </Paragraph>
       )}
      <Orders userOrders={userOrders} />
    </div>
  )
);

export default ProfileHistory;

import ProfileFields from './ProfileFields/profileFields';
import ProfileHistory from './ProfileHistory/profileHistory';
import ProfileTabs from './ProfileTabs/profileTabs';
import { ProfileH2, ProfileIsEmpty } from './profile.module.css';
import Layout from '../../components/layout';
import strapiAPI from '../../Utils/api/strapiApi';
import H2 from '../../components/Blocks/H2/H2';
import Paragraph from '../../components/Blocks/Paragraph/paragraph';
import CashbackBadge from '../../components/Blocks/CashbackBadge/cashbackBadge';
import React, { useEffect, useState } from 'react';
import { captureException } from '@sentry/minimal';
import Cookies from 'js-cookie';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

interface ProfileProps {
  location: any;
}

const Profile = ({ location }: ProfileProps) => {
  const [activeTab, setActiveTab] = useState(0);
  const [userOrders, setUserOrders] = useState([]);
  const [userCurrentOrders, setUserCurrentOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [noOrders, setNoOrders] = useState(false);
  const [openOrderAlert, setOpenOrderAlert] = useState(false);
  const [cashback, setCashback] = useState(null);
  const [infoFromApi, setInfoFromApi] = useState({
    Name: null,
    Phone: null,
    Email: null,
  });

  const userId = Cookies.getJSON('user_id');

  useEffect(() => {
    const controller = new AbortController();

    (async () => {
      setIsLoading(true);
      await strapiAPI.post('/users-permissions/getuserbyid', {
        customerID: userId,
      }, {
        signal: controller.signal
      }).then((res) => {
        const { data } = res;

        setCashback(res.data.walletBalances[0].balance);

        setInfoFromApi({
          Name: data.name || null,
          Phone: data.phone || null,
          Email: data.email || null,
        });

        return strapiAPI.post('/users-permissions/getuserordersbyphone', {
          phone: data.phone.replace('+', ''),
        }, {
          signal: controller.signal
        });
      })
        .then((res) => {
          const formatedOrders = res.data.customersDeliveryHistory.map((val) => val.deliveryHistory)
            .reduce((acc, cur) => acc.concat(cur))
            .sort((a, b) => new Date(b.date) - new Date(a.date));
          const currentOrders = formatedOrders.filter((order) => order.status !== 'CANCELLED' || order.status !== 'CLOSED' || order.status !== 'DELIVERED');

          setIsLoading(false);
          setUserOrders(formatedOrders);
          setUserCurrentOrders(currentOrders);

          return updateOrders(formatedOrders, currentOrders);
        })
        .catch((err) => {
          captureException(err);
          setNoOrders(true);
        });
    })();

    () => {
      controller.abort()
    }
  }, []);

  const updateOrders = async (oldOrders, currentOrders) => {
    setIsLoading(true);

    // loop through that orders and make api requist, save in responses array
    const responses = [];

    try {
      for (let i = 0; i < currentOrders.length; i += 1) {
        /* eslint-disable no-await-in-loop */
        responses.push(await strapiAPI.post('/users-permissions/getorderbyid', {
          orderID: currentOrders[i].orderId,
        }));
      }
    } catch (error) {
      setOpenOrderAlert(true);
      setIsLoading(false);
    }

    // if all ok, replace oldOrders array with new data
    if (responses.length > 0) {
      const updatedOrders = oldOrders
        .map((ord) => {
          const findOrder = responses
            .find((n) => n.data.orderId === ord.orderId);

          return findOrder ? findOrder.data : ord;
        });

      setUserOrders(updatedOrders);
      setIsLoading(false);
    }
  };

  const onCloseSnackbar = () => {
    setOpenOrderAlert(false);
  };

  return (
    <Layout location={location} headerIsStatic>
      <div
        className={ProfileH2}
        style={{
          width: '100%', display: 'flex', alignItems: 'center',
        }}
      >
        <H2>Профиль</H2>
        <CashbackBadge cashback={cashback} marginLeft={24} />
      </div>
      {noOrders ? <Paragraph className={ProfileIsEmpty}>У вас пока нет заказов</Paragraph>
        : (
          <>
            <ProfileTabs
              setActiveTab={setActiveTab}
              activeTab={activeTab}
            />
            {location.search === '?tab=settings'
              ? <ProfileFields isLoading={isLoading} infoFromApi={infoFromApi} />
              : <ProfileHistory isLoading={isLoading} userOrders={userOrders} />}
          </>
        )}

      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={openOrderAlert}
        onClose={onCloseSnackbar}
      >
        <Alert
          severity="error"
        >
          Не получилось загрузить заказы, попробуйте перезапустить страницу.
        </Alert>
      </Snackbar>
    </Layout>
  );
};

export default Profile;

import {
  ProfileFieldsContacts as ProfileFieldsContactsClass, ProfileFieldsContactsH2, ProfileFieldsContactsContainer, ProfileFieldsContactsButton,
} from './profileFieldsContacts.module.css';
import ButtonProduct from '../../../../components/Blocks/ButtonProduct/buttonProduct';
import H2 from '../../../../components/Blocks/H2/H2';
import changeContactInfo from '../../../../Utils/api/changeContactInfo';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import React, { useState } from 'react';
import { captureException } from '@sentry/gatsby';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      marginRight: theme.spacing(1),
    },
  },
}));
interface ProfileFieldsContactsProps {
    formik: {},
}

const ProfileFieldsContacts = ({
  formik, editingContacts, setEditingContacts,
}: ProfileFieldsContactsProps) => {
  const classes = useStyles();
  const [reqIsErr, setReqIsErr] = useState(false);
  const [reqIsSucc, setReqIsSucc] = useState(false);

  const handleChangeValues = () => changeContactInfo(formik.values.phone,
    formik.values.email, formik.values.name)
    .then((val) => setReqIsSucc(true))
    .catch((err) => {
      setReqIsErr(true);
      captureException(err);
    });

  return (
    <form onSubmit={formik.handleSubmit} className={`${ProfileFieldsContactsClass} ${classes.root}`}>
      <H2 className={ProfileFieldsContactsH2}>Контакты</H2>
      <div className={ProfileFieldsContactsContainer}>
        <TextField
          id="name-contactsform"
          label="Имя:"
          type="text"
          autoComplete="name"
          placeholder="Лава Молинари"
          fullWidth
          disabled={!editingContacts || reqIsSucc}
          name="name"
          margin="normal"
          variant="outlined"
          value={formik.values.name}
          onChange={formik.handleChange}
          error={Boolean(formik.errors.name)}
          helperText={formik.errors.name}
        />
        <TextField
          id="tel-contactsform"
          label="Номер телефона:"
          autoComplete="tel"
          type="tel"
          inputMode="tel"
          disabled
          margin="normal"
          name="phone"
          placeholder="7982834242"
          fullWidth
          variant="outlined"
          value={formik.values.phone}
          onChange={formik.handleChange}
          error={Boolean(formik.errors.phone)}
          helperText={formik.errors.phone}
        />
        <TextField
          id="email-contactsform"
          label="Имейл:"
          name="email"
          autoComplete="email"
          inputMode="email"
          margin="normal"
          disabled={!editingContacts || reqIsSucc}
          type="email"
          placeholder="lava@хочу-суши.рф"
          fullWidth
          variant="outlined"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={Boolean(formik.errors.email)}
          helperText={formik.errors.email}
        />
      </div>
      {!editingContacts && <ButtonProduct onClick={() => setEditingContacts(true)} className={ProfileFieldsContactsButton} label="Изменить" />}
      {editingContacts && <ButtonProduct isPressed={reqIsSucc} isPressedLabel="Сохранено" onClick={handleChangeValues} className={ProfileFieldsContactsButton} label="Готово" />}
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={reqIsErr}
      >
        <Alert
          severity="error"
        >
          Не получилось обновить информацию.
        </Alert>
      </Snackbar>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={reqIsSucc}
      >
        <Alert
          severity="success"
        >
          Информация обновлена!
        </Alert>
      </Snackbar>
    </form>
  );
};

export default ProfileFieldsContacts;
